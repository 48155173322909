/* eslint-disable react-hooks/exhaustive-deps */
import { Spin, Table, Tooltip, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import Cookies from 'js-cookie';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetInventoryByPostQuery } from 'redux/services/mrJohnson/mrJohnsonApi';
import { ReduxState } from 'redux/store';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import WimsicalError from 'components/atoms/WimsicalError/WimsicalError';
import { QueryErrorModel } from 'models/ErrorModel';
import { InventoryOnHand } from 'models/InventoryOnHand';
import ReactDragListView from 'react-drag-listview';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { formatErrorMessage } from 'utils/formatErrorMessage';

export const InventoryTable = (): JSX.Element => {
  const localStorageOrder = localStorage.getItem('columnOrder');
  const columnOrder =
    localStorageOrder !== null
      ? (JSON.parse(localStorageOrder) as string[])
      : ['productNumber', 'alternateItemId', 'owner', 'condition', 'disposition', 'manufacturerName', 'warehouse', 'qtyOnHand', 'qtyAvailable', 'qtyOnPO', 'qtyOnSo', 'qtyPicked', 'qtyReserved'];

  const { acuityContext } = useSelector((state: ReduxState) => state.app);

  const id = acuityContext?.selectedCustomer.id;
  const pageSizeCookie = parseInt(Cookies.get('pageSize') ?? '25');

  const { inventoryParams } = useSelector((state: ReduxState) => state);

  const { data, isLoading, isFetching, error, isError } = useGetInventoryByPostQuery(inventoryParams);
  const { data: xRefData, isLoading: isXrefLoading } = useGetXrefConfigQuery(id ? id : skipToken);

  const [columnOrderState, setColumnOrderState] = useState(columnOrder);

  const productNumber = {
    title: <Typography.Text strong>Product Number</Typography.Text>,
    dataIndex: 'productNumber',
    key: 'productNumber',
    render: (_: string, record: InventoryOnHand): JSX.Element => (
      <Tooltip title={record.productNumber ?? 'No name available'}>
        <Typography.Text>{record.productNumber ?? ''}</Typography.Text>
      </Tooltip>
    )
  };
  const altItemId = {
    title: (
      <Spin spinning={isXrefLoading}>
        <Typography.Text strong>{xRefData?.alternateItemIdLabel || 'Alt Item Id'}</Typography.Text>
      </Spin>
    ),
    dataIndex: 'alternateItemId',
    key: 'alternateItemId',
    render: (_: string, record: InventoryOnHand): JSX.Element => <Typography.Text>{record.alternateItemId ?? ''}</Typography.Text>
  };
  const manufacturerName = {
    title: <Typography.Text strong>Manufacturer</Typography.Text>,
    dataIndex: 'manufacturerName',
    key: 'manufacturerName'
  };
  const owner = {
    title: <Typography.Text strong>Owner</Typography.Text>,
    dataIndex: 'ownerId',
    key: 'ownerId',

    render: (_: string, record: InventoryOnHand): JSX.Element => <Typography.Text>{record.ownerId ?? ''}</Typography.Text>
  };
  const condition = {
    title: <Typography.Text strong>Condition</Typography.Text>,
    dataIndex: 'conditionId',
    key: 'conditionId',

    render: (_: string, record: InventoryOnHand): JSX.Element => <Typography.Text>{record.conditionId ?? ''}</Typography.Text>
  };
  const disposition = {
    title: <Typography.Text strong>Disposition</Typography.Text>,
    dataIndex: 'dispositionId',
    key: 'dispositionId',

    render: (_: string, record: InventoryOnHand): JSX.Element => <Typography.Text>{record.dispositionId ?? ''}</Typography.Text>
  };
  const warehouse = {
    title: <Typography.Text strong>Warehouse</Typography.Text>,
    dataIndex: 'warehouseId',
    key: 'warehouseId',

    render: (_: string, record: InventoryOnHand): JSX.Element => <Typography.Text>{record.warehouseId ?? ''}</Typography.Text>
  };
  const qtyOnHand = {
    title: <Typography.Text strong>Qty on Hand</Typography.Text>,
    dataIndex: 'quantityOnHand',
    key: 'quantityOnHand'
  };
  const qtyAvail = {
    title: <Typography.Text strong>Qty Available</Typography.Text>,
    dataIndex: 'quantityAvailable',
    key: 'quantityAvailable'
  };
  const qtyOnSO = {
    title: <Typography.Text strong>Qty On SO</Typography.Text>,
    dataIndex: 'quantityOnSalesOrders',
    key: 'quantityOnSalesOrders'
  };
  const qtyOnPO = {
    title: <Typography.Text strong>Qty on PO</Typography.Text>,
    dataIndex: 'quantityOpenOnPurchaseOrders',
    key: 'quantityOpenOnPurchaseOrders'
  };
  const qtyPicked = {
    title: <Typography.Text strong>Qty Picked</Typography.Text>,
    dataIndex: 'quantityPicked',
    key: 'quantityPicked'
  };
  const qtyReserved = {
    title: <Typography.Text strong>Qty Reserved</Typography.Text>,
    dataIndex: 'quantityReserved',
    key: 'quantityReserved'
  };

  const dragProps = {
    onDragEnd(fromIndex: number, toIndex: number): void {
      const columns = [...columnOrder];
      const item = columns.splice(fromIndex, 1)[0];

      columns.splice(toIndex, 0, item);
      setColumnOrderState(columns);
      localStorage.setItem('columnOrder', JSON.stringify(columns));
    },
    nodeSelector: 'th'
  };

  const columns = useMemo(
    () =>
      columnOrderState.map((col: string) => {
        switch (col) {
          case 'productNumber':
            return productNumber;

          case 'alternateItemId':
            return altItemId;

          case 'owner':
            return owner;

          case 'condition':
            return condition;

          case 'disposition':
            return disposition;

          case 'manufacturerName':
            return manufacturerName;

          case 'warehouse':
            return warehouse;

          case 'qtyOnHand':
            return qtyOnHand;

          case 'qtyAvailable':
            return qtyAvail;

          case 'qtyOnSo':
            return qtyOnSO;

          case 'qtyOnPO':
            return qtyOnPO;

          case 'qtyPicked':
            return qtyPicked;

          case 'qtyReserved':
            return qtyReserved;

          default:
            return {};
        }
      }),
    [columnOrderState, condition, disposition, qtyAvail, owner, productNumber, qtyOnHand, qtyOnHand, qtyOnSO, qtyOnPO, warehouse, qtyPicked, qtyReserved, manufacturerName]
  );

  if (isError) {
    const err = error as QueryErrorModel;

    return <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />;
  }

  return (
    <ReactDragListView.DragColumn {...dragProps}>
      <Table
        loading={{ spinning: isLoading || isFetching, indicator: <BlockLoader direction="loader loader--slideUp" /> }}
        columns={columns}
        bordered
        size="small"
        pagination={{
          style: { marginRight: 16 },
          onShowSizeChange: (_, size): void => {
            Cookies.set('pageSize', size.toString());
          },
          pageSizeOptions: ['25', '75', '200', '500'],
          defaultPageSize: pageSizeCookie,
          total: data?.length ?? 0
        }}
        dataSource={data}
        rowKey={(record): string => `${record.ownerId} - ${record.dispositionId} - ${record.conditionId} - ${record.productNumber} - ${record.warehouseId}`}
      />
    </ReactDragListView.DragColumn>
  );
};
