import { Button, message } from 'antd';
import { useSelector } from 'react-redux';
import { useGetInventoryByPostQuery, useLazyGetInventoryByPostQuery } from 'redux/services/mrJohnson/mrJohnsonApi';
import { ReduxState } from 'redux/store';
import * as xlsx from 'xlsx';

export const ExportInventoryButton = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { inventoryParams } = useSelector((state: ReduxState) => state);
  const [triggerAll, { isLoading: isExporting, isFetching: isExportFetching }] = useLazyGetInventoryByPostQuery();

  const { data, isLoading, isFetching } = useGetInventoryByPostQuery(inventoryParams);

  /* ******************** Functions ******************** */

  const handleExport = async (): Promise<void> => {
    try {
      const allData = await triggerAll({ ...inventoryParams }).unwrap();

      const removedSite = allData?.map(
        ({
          productNumber,
          ownerId,
          conditionId,
          dispositionId,
          quantityAvailable,
          quantityOnSalesOrders,
          quantityOnHand,
          quantityOpenOnPurchaseOrders,
          quantityPicked,
          quantityReserved,
          manufacturerName,
          warehouseId
        }) => ({
          Number: productNumber,
          Owner: ownerId,
          Condition: conditionId,
          Disposition: dispositionId,
          Manufacturer: manufacturerName,
          Warehouse: warehouseId,
          QuantityAvailable: quantityAvailable,
          QuantityOnHand: quantityOnHand,
          QuantityOnSalesOrders: quantityOnSalesOrders,
          QuantityOpenOnPurchaseOrders: quantityOpenOnPurchaseOrders,
          QuantityPicked: quantityPicked,
          QuantityReserved: quantityReserved
        })
      );

      const worksheeet = xlsx.utils.json_to_sheet(removedSite);
      const workbook = xlsx.utils.book_new();

      xlsx.utils.book_append_sheet(workbook, worksheeet, 'inventory');
      xlsx.writeFile(workbook, 'inventory-on-hand.xlsx');
      message.success('Data successfully exported');
    } catch (err) {
      console.log(err);
      message.error((err as any).data.errorMessage);
    }
  };

  const isExportLoading = isLoading || isFetching || isExporting || isExportFetching;

  /* ******************** Render ******************** */

  return (
    <Button disabled={!data?.length} onClick={handleExport} loading={isExportLoading}>
      {isExportLoading ? 'Loading' : `Export ${data?.length ?? 0} item(s)`}
    </Button>
  );
};
