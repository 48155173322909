import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InventoryParams } from 'models/InventoryOnHand';

const initialState: InventoryParams = {
  alternateItemIdsEqual: undefined,
  alternateItemIdsContains: undefined,
  productNumbersEqual: [],
  productNumbersContain: [],
  manufacturerIdsEqual: [],
  manufacturerIdsContain: [],
  warehouseIdsEqual: [],
  ownerIdsEqual: [],
  conditionIdsEqual: [],
  conditionIdContains: undefined,
  dispositionIdsEqual: [],
  dispositionIdContains: undefined
};

export const inventoryParams = createSlice({
  name: 'inventoryParams',
  initialState,
  reducers: {
    setAlternateItemIdEquals: (state, { payload }: PayloadAction<InventoryParams['alternateItemIdsEqual']>) => {
      state.alternateItemIdsEqual = payload;
    },
    setAlternateItemIdContains: (state, { payload }: PayloadAction<InventoryParams['alternateItemIdsContains']>) => {
      state.alternateItemIdsContains = payload;
    },
    setProductNumbersEqual: (state, { payload }: PayloadAction<InventoryParams['productNumbersEqual']>) => {
      state.productNumbersEqual = payload;
    },
    setProductNumberContains: (state, { payload }: PayloadAction<InventoryParams['productNumbersContain']>) => {
      state.productNumbersContain = payload;
    },
    setManufacturerNamesEqual: (state, { payload }: PayloadAction<InventoryParams['manufacturerIdsEqual']>) => {
      state.manufacturerIdsEqual = payload;
    },
    setManufacturerNameContains: (state, { payload }: PayloadAction<InventoryParams['manufacturerIdsContain']>) => {
      state.manufacturerIdsContain = payload;
    },

    setWarehouseIdsEqual: (state, { payload }: PayloadAction<InventoryParams['warehouseIdsEqual']>) => {
      state.warehouseIdsEqual = payload;
    },
    setOwnerIdsEqual: (state, { payload }: PayloadAction<InventoryParams['ownerIdsEqual']>) => {
      state.ownerIdsEqual = payload;
    },

    setConditionIdContains: (state, { payload }: PayloadAction<InventoryParams['conditionIdContains']>) => {
      state.conditionIdContains = payload;
    },
    setConditionIdsEqual: (state, { payload }: PayloadAction<InventoryParams['conditionIdsEqual']>) => {
      state.conditionIdsEqual = payload;
    },
    setDispositionIdsEqual: (state, { payload }: PayloadAction<InventoryParams['dispositionIdsEqual']>) => {
      state.dispositionIdsEqual = payload;
    },
    setDispositionIdContains: (state, { payload }: PayloadAction<InventoryParams['dispositionIdContains']>) => {
      state.dispositionIdContains = payload;
    }
  }
});

export const {
  setConditionIdContains,
  setConditionIdsEqual,
  setDispositionIdContains,
  setDispositionIdsEqual,
  setManufacturerNameContains,
  setManufacturerNamesEqual,
  setOwnerIdsEqual,
  setProductNumberContains,
  setProductNumbersEqual,
  setWarehouseIdsEqual,
  setAlternateItemIdContains,
  setAlternateItemIdEquals
} = inventoryParams.actions;
